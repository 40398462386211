define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object"], function (_exports, _decorators, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    showPreview: false,
    classNameBindings: [":wizard-field-composer", "showPreview:show-preview:hide-preview"],
    init() {
      this._super(...arguments);
      this.set("composer", _object.default.create({
        loading: false,
        reply: this.get("field.value") || ""
      }));
    },
    setField() {
      this.set("field.value", this.get("composer.reply"));
    },
    togglePreviewLabel(showPreview) {
      return showPreview ? "wizard_composer.hide_preview" : "wizard_composer.show_preview";
    },
    importQuote() {},
    groupsMentioned() {},
    afterRefresh() {},
    cannotSeeMention() {},
    showUploadSelector() {},
    togglePreview() {
      this.toggleProperty("showPreview");
    }
  }, [["method", "setField", [(0, _decorators.observes)("composer.reply")]], ["method", "togglePreviewLabel", [(0, _decorators.default)("showPreview")]], ["method", "importQuote", [_object.action]], ["method", "groupsMentioned", [_object.action]], ["method", "afterRefresh", [_object.action]], ["method", "cannotSeeMention", [_object.action]], ["method", "showUploadSelector", [_object.action]], ["method", "togglePreview", [_object.action]]]));
});